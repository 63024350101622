.loginPage {
  > ion-content {
    position: relative;
    padding-bottom: 60px;
    > ion-grid {
      min-height: 100%;
      padding-bottom: 60px;
    }
    > ion-footer {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}
