.mode {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .5) !important;
  display: flex;
  z-index: 9999;
  align-items: center;
  justify-content: center;
}

.modeModal {
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0px 3px 6px #343A40;
  padding: 30px 20px;
  width: 90%;
  color: #343A40;

  .modeModalTitle {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
    display: block;
  }

  .modeModalDesc {
    font-size: 16px;
    margin-bottom: 20px;
    font-weight: normal;
    display: block;
  }

  .checkLabel {
    font-weight: normal;
    font-size: 16px;
  }

  &.dark {
    background-color: #343A40;
    color: #fff;
  }
}