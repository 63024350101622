.businessBanner {
  background-image: url(./business_back2.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex; 
  padding: 32px 16px;
  align-items: center;
  cursor: pointer;

  ion-label {
    color: #fff !important;
    flex: 1;
    font-size: 14px;
    text-align: center;
  }

  ion-icon {
    font-size: 24px;
    margin-left: 12px;
  }
}

.platium {
  padding: 60px 16px 50px;
  background-color: #59BCE4;
  color: #fff;
}

.diamond {
  padding: 60px 16px 50px;
  background-color: #000;
  color: #fff;
}

.gold {
  padding: 60px 16px 50px;
  background-color: #E29F32;
  color: #fff;
}

.silver {
  padding: 60px 16px 50px;
  background-color: #fff;
  color: #59BCE4;
}

.line {
  flex: 1;
  height: 3px;
  background-color: #fff;
}

.silver .line {
  background-color: #59BCE4;
}

.businessLogo {
  width: 100%;
  position: relative;
  padding-top: 20%;
  overflow: hidden;

  ion-img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
  }
}