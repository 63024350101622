.flex-1 {
  flex: 1;
}

.obj-cover {
  object-fit: cover;
}

.obj-contain {
  object-fit: contain;
}

.d-block {
  display: block;
}

.d-inline-block {
  display: inline-block;
}

.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.flex-direction-column {
  flex-direction: column;
}