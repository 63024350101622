@import "./common/margin.scss";
@import "./common/font.scss";
@import "./common/color.scss";
@import "./common/layout.scss";
@import "./common/border.scss";
@import "./common/button.scss";
@import "./common/sweet.scss";

@mixin sp-only {
  @media (max-width: 767px) {
    @content;
  }
}

.home-content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  flex-direction: column;

  & > img {
    width: 200px;
    height: 200px;
    object-fit: cover;
  }
}

.custom-loading-css {
  .backdrop-no-tappable {
    background-color: #5ecbf7;
  }

  .loading-wrapper {
    padding: 0;
    background-color: transparent;
    box-shadow: none;
    width: 60px;
    height: 60px;

    .loading-spinner {
      width: 100%;
      height: 100%;

      .spinner-crescent {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.main-container {
  min-height: 87vh;
}

.app-icon {
  border-radius: 50%;
  overflow: hidden;
}

.custom-select {
  width: 100%;
  background-color: #fff;
  color: #343a40;
  height: 100%;
  border: 0px;
  border-bottom: 2px solid #7bc9f3;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;

  // background-image:
  //   linear-gradient(45deg, transparent 50%, #5ECBF7 50%),
  //   linear-gradient(135deg, #5ECBF7 50%, transparent 50%),
  //   radial-gradient(#fff 70%, transparent 72%);
  // background-position:
  //   calc(100% - 20px) calc(1.5em + 4px),
  //   calc(100% - 10px) calc(1.5em + 4px),
  //   calc(100% - 1em) 1em;
  // background-size:
  //   10px 10px,
  //   10px 10px,
  //   3em 3em;
  // background-repeat: no-repeat;
}

.toggleWrapper {
  background-color: transparent;
}
.toggleWrapper input.mobileToggle {
  opacity: 0;
  position: absolute;
}
.toggleWrapper input.mobileToggle + label {
  position: relative;
  display: inline-block;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: 0.4s ease;
  height: 24px;
  border-radius: 48px;
  padding-left: 52px;
  line-height: 24px;
}
.toggleWrapper input.mobileToggle + label:before {
  content: "";
  position: absolute;
  display: block;
  transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
  height: 25px;
  width: 40px;
  top: 0;
  left: 0;
  border-radius: 26px;
  background-color: #c0c3c4;
}
.toggleWrapper input.mobileToggle + label:after {
  content: "";
  position: absolute;
  display: block;
  box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 0px 0 hsla(0, 0%, 0%, 0.04),
    0 4px 9px hsla(0, 0%, 0%, 0.13), 0 3px 3px hsla(0, 0%, 0%, 0.05);
  transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
  background: whitesmoke;
  height: 18px;
  width: 18px;
  top: 3px;
  left: 3px;
  border-radius: 60px;
}
.toggleWrapper input.mobileToggle:checked + label:before {
  background: #2178c3;
  transition: width 0.2s cubic-bezier(0, 0, 0, 0.1);
}
.toggleWrapper input.mobileToggle:checked + label:after {
  left: 19px;
}

.top-link-item {
  position: relative;
  width: 100%;
  padding-top: 118%;

  .top-link-item-tab {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;

    .icon-class {
      width: 90px;
      height: 90px;
      object-fit: contain;

      @include sp-only() {
        width: 80px;
        height: 80px;
      }
    }
  }
}

.spinner-crescent {
  width: 48px;
  height: 48px;
}

.vertical-align-middle {
  vertical-align: middle;
}

.vertical-align-text-top {
  vertical-align: text-top;
}

.vertical-align-text-bottom {
  vertical-align: text-bottom;
}

.word-break-all {
  word-break: break-all;
}

.overflow-hidden {
  overflow: hidden;
}

.html-code {
  p {
    margin-bottom: 4px;
  }
}

.html-brief {
  line-height: 20px;
  max-height: 64px;
  overflow: hidden;
  word-break: break-all;
  p {
    margin: 0;
  }
}

.box-shadow-none {
  box-shadow: none;
}

.searchbar-input-container {
  height: 40px;
}

.searchbar-left-aligned .searchbar-input {
  height: 40px;
  line-height: 40px;
  background-color: #fff !important;
  border: 2px solid #5ecbf7;
  border-radius: 20px;
  padding-left: 40px !important;
}

.searchbar-search-icon {
  // left: auto;
  // right: 12px;
  left: 10px !important;
}

.btn-comment {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9999;
}

.my-custom-modal #ion-react-wrapper {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.pre-wrap {
  white-space: pre-wrap;
}

.cursor {
  cursor: pointer;
}

.transition {
  transition: 0.3s;

  &:hover {
    opacity: 0.7;
  }
}

.underline {
  text-decoration: underline;
}

.zoomer {
  background-color: #000;
  color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 9999;
}

.img-cover {
  object-fit: cover;
}

.img-contain {
  object-fit: contain;
}

.zoom-overlay-1 {
  position: fixed;
  z-index: 9999;
  top: 0px;
  left: 0px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 0.7);
}

.zoom-overlay-2 {
  position: fixed;
  z-index: 9999;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: auto;
  padding: 15px;
  background-color: rgba(0, 0, 0, 0.7);
}

.zoomer-content {
  height: 100vh;
  width: 100%;
  position: relative;
  z-index: 9999;

  .icon-prev {
    position: absolute;
    top: calc(50vh - 19px);
    left: 0px;
    z-index: 9999;
  }

  .icon-next {
    position: absolute;
    top: calc(50vh - 19px);
    right: 0px;
    z-index: 9999;
  }

  .zoomer-slider {
    height: 100vh;

    .slider-item {
      width: 100%;
      height: 100vh;
      overflow: hidden;
    }
  }
}

.visible-hidden {
  visibility: hidden;
}

.visible {
  visibility: visible;
}

.business-logo {
  position: relative;
  width: 100%;
  padding-top: 20%;
  overflow: hidden;
  display: block;

  ion-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

// ion-content {
//   // overwrite inline styles
//   --offset-bottom: auto !important;
//   --overflow: hidden;
//   overflow: auto;
//   background-color: #fff !important;
//   &::-webkit-scrollbar {
//     display: none;
//   }
// }

// ion-content {
//   width: calc(100% + 14px);
// }
// ion-content::part(scroll) {
//   padding-right: 14px;
// }

.dark-mode {
  ion-grid {
    background-color: #343A40;
    color: #fff !important;

    &.zoomer {
      background-color: #000;
    }
  }

  .color-black {
    color: #fff !important;

    &.back-yellow,
    &.back-light-gray {
      color: #343A40 !important;
    }
  }

  .swal2-popup {
    background: #343A40 !important;
    color: #fff;
  }

  .deleted-item {
    background-color: rgba(0, 0, 0, .5) !important;
    color: #CCCDCF !important;
  }

  .search-bar {
    background-color: rgba(0, 0, 0, .5) !important;
  }
  
  ion-list .color-black-light {
    color: #fff !important;
  }

  ion-list .item-native {
    background-color: #343A40 !important;
  }

  .back-light-gray2 {
    background-color: #000 !important;
    color: #fff;
  }
}