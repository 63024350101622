.businessBanner {
  background-image: url(./business_back.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex; 
  padding: 16px;
  align-items: center;
  cursor: pointer;

  ion-label {
    color: #fff !important;
    flex: 1;

    div:first-child {
      font-size: 20px;
      font-weight: bold;
    }

    div:last-child {
      font-size: 12px;
    }
  }

  ion-icon {
    font-size: 24px;
    margin-left: 12px;
  }
}