.bannerImage {
  width: 100%;
  position: relative;
  padding-top: 100%;
  overflow: hidden;
  cursor: pointer;
  background: #eee;
  img {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    object-fit: cover;
  }
}
