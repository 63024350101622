.btn {
  box-shadow: none;
}

a,
button {
  transition: .3s;

  &:hover {
    opacity: .7;
  }
}

.btn-light-blue {
  background-color: #5ECBF7;
  border-color: #5ECBF7;
  color: #fff;
  text-align: center;

  &:hover,
  &:active,
  &:focus {
    background-color: #5ECBF7;
    border-color: #5ECBF7;
    color: #fff;
  }
}

.btn-yellow {
  background-color: #FDF13C;
  border-color: #FDF13C;
  color: #343A40;
  text-align: center;

  &:hover,
  &:active,
  &:focus {
    background-color: #FDF13C;
    border-color: #FDF13C;
    color: #343A40;
  }
}

.btn-blue {
  background-color: #2178C3;
  border-color: #2178C3;
  color: #fff;
  text-align: center;

  &:hover,
  &:active,
  &:focus {
    background-color: #2178C3;
    border-color: #2178C3;
    color: #fff;
  }
}

.btn-orange {
  background-color: #EF6325 !important;
  border-color: #EF6325 !important;
  color: #fff;
  text-align: center;

  &:hover,
  &:active,
  &:focus {
    background-color: #EF6325 !important;
    border-color: #EF6325 !important;
    color: #fff;
  }
}

.btn-green {
  background-color: #32B681;
  border-color: #32B681;
  color: #fff;
  text-align: center;

  &:hover,
  &:active,
  &:focus {
    background-color: #32B681;
    border-color: #32B681;
    color: #fff;
  }
}

.btn-black {
  background-color: #343A40;
  border-color: #343A40;
  color: #fff;
  text-align: center;

  &:hover,
  &:active,
  &:focus {
    background-color: #343A40;
    border-color: #343A40;
    color: #fff;
  }
}

.btn-gray {
  background-color: #C0C3C4;
  border-color: #C0C3C4;
  color: #343A40;
  text-align: center;

  &:hover,
  &:active,
  &:focus {
    background-color: #C0C3C4;
    border-color: #C0C3C4;
    color: #343A40;
  }
}

.btn-dark-gray {
  background-color: #D6D9DA;
  border-color: #D6D9DA;
  color: #343A40;
  text-align: center;

  &:hover,
  &:active,
  &:focus {
    background-color: #D6D9DA;
    border-color: #D6D9DA;
    color: #343A40;
  }
}

.btn-disabled {
  opacity: .6;

  &:hover, 
  &:active,
  &:focus {
    opacity: .6;
  }
}

.button-native {
  box-shadow: none;
}

.btn-block {
  width: 100%;
}