.bannerImage {
  position: relative;
  padding-top: 20%;
  overflow: hidden;
  cursor: pointer;
  margin: -1px -10px 0px;
  width: calc(100% + 20px);
  background: #eee;
  img {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    object-fit: cover;
  }

  @media (max-width: 768px) {
    padding-top: 20vw;
    width: 100vw;
  }
}

.articleItem {
  padding-top: 0;
  padding-bottom: 0;
}
