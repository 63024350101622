.sweet-alert-success {
  color: #32B681;
}

.swal2-icon.swal2-success .swal2-success-ring {
  border-color: rgba(50, 182, 129, .3) !important;
}

// .swal2-icon.swal2-success.swal2-icon-show .swal2-success-line-tip,
// .swal2-icon.swal2-success.swal2-icon-show .swal2-success-line-long {
//   background-color: #32B681 !important;
// }

.sweet-alert-error {
  color: #f27474;
}

.sweet-alert-warning {
  color: #f8bb86;
}

.swal2-title {
  font-size: 1.5rem !important;
}

.swal2-html-container {
  font-size: 1rem !important;
}

.page-item .page-link {
  border: none;
  background-color: transparent;
  color: #2178C3;
}

.page-item.active .page-link {
  border: none;
  background-color: transparent;
  color: #6c757d;
}
.swal2-confirm,
.swal2-cancel {
  min-width: 160px;
  border-radius: 30px !important;
  min-height: 45px;
  font-weight: bold;
  font-size: 20px;
}

.custom-cancel-button {
  color: #343A40 !important;
  background-color: #E3E5E5 !important;

  &:hover, 
  &:active,
  &:focus {
    border-color: #E3E5E5 !important;
    box-shadow: none !important;
  }
}

.swal2-icon.swal2-success {
  width: 60px !important;
  height: 60px !important;
  margin-bottom: 0 !important;
  border-color: transparent !important;
  color: transparent !important;

  .swal2-success-line-tip {
    background-color: #fff !important;
    z-index: 10 !important;
    top: 30px !important;
    width: 20px !important;
    left: 6px !important;
  }

  .swal2-success-line-long {
    background-color: #fff !important;
    z-index: 10 !important;
    top: 24px !important;
    width: 32px !important;
    right: 12px !important;
  }

  .swal2-success-ring {
    border: 0 !important;
    background: #32B681 !important;
  }
}

.swal2-icon-success .swal2-html-container {
  text-align: left !important;
}