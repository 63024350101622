$borderRadiusSize: 2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 24, 30;

.border-1 {
  border: 1px solid #5ECBF7;
}

.border-gray-1 {
  border: 1px solid #E3E5E5;
}

.border-2 {
  border: 2px solid #5ECBF7;
}

.border-bottom-1 {
  border-bottom: 1px solid #E3E5E5;
}

.border-orange {
  border-color: #EF6325;
}

.border-yellow {
  border-color: #F6AA31;
}

.border-green {
  border-color: #32B681;
}

@each $size in $borderRadiusSize {
  $newSize: calc(1rem * $size / 16);
  .border-radius-#{$size} {
    border-radius: $newSize;
  }
}

.no-border-top-right-radius {
  border-top-right-radius: 0px;
}
.no-border-bottom-right-radius {
  border-bottom-right-radius: 0px;
}
.no-border-top-left-radius {
  border-top-left-radius: 0px;
}
.no-border-bottom-left-radius {
  border-bottom-left-radius: 0px;
}

.no-border-left {
  border-left: 0px;
}

.border-radius-round {
  border-radius: 50%;
}
