.topPage {
  ion-grid {
    padding: 1.25rem 0 !important;

    ion-row {
      padding: 0 1.5px !important;
      ion-col {
        padding: 0 1.5px 3px !important;
      }
    }
  }
}