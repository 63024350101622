$sizes: 0, 1, 2, 4, 8, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 36, 38, 40, 42, 45, 50, 56, 60, 75, 80, 84, 100, 120, 134, 140, 150, 170, 200, 240, 300;

.en-font {
  font-family: 'Oswald', sans-serif;
}

.text-upper {
  text-transform: uppercase;
}

.text-right {
  text-align: right;
}

.no-decoration {
  text-decoration: none;
}

.text-medium {
  font-weight: 500;
}

.text-bold {
  font-weight: bold;
}

.text-normal {
  font-weight: 400;
}

.text-center {
  text-align: center;
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

@each $size in $sizes {
  $newSize: calc(1px * $size); // calc(1rem * $size / 16);
  .width-#{$size} {
    width: $newSize;
  }

  .height-#{$size} {
    height: $newSize;
  }

  .font-#{$size} {
    font-size: $newSize;
  }

  .line-height-#{$size} {
    line-height: $newSize;
  }

  .max-width-#{$size} {
    max-width: $newSize;
  }

  .max-height-#{$size} {
    max-height: $newSize;
  }
}
