.bannerImage {
  width: 100%;
  position: relative;
  padding-top: 100%;
  overflow: hidden;
  cursor: pointer;
  background: #eee;
  img {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    object-fit: cover;
  }
}

.checkImage {
  width: 20px;
  height: 20px;
  object-fit: contain;
  margin-right: 20px;
}

.agree {
  background-color: #2178C3;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  flex: 1;
  cursor: pointer;
  line-height: 50px;
  height: 50px;
  color: #fff;
}

.disagree {
  background-color: rgba(33, 120, 195, .5);
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  flex: 1;
  cursor: pointer;
  line-height: 50px;
  height: 50px;
  color: #fff;
}