.color-light-gray {
  color: #C0C3C4;
}

.color-black {
  color: #343A40;
}

.color-black-light {
  color: rgba(52, 58, 64, .75);
}

.color-orange {
  color: #EF6325 !important;
}

.back-red {
  background-color: #FF0000;
}

.back-light-gray {
  background-color: #E3E5E5;
}

.back-light-gray2 {
  background-color: rgba(240, 240, 240, .6);
}

.back-light-blue {
  background-color: rgba(94, 203, 247, .25);
}

.back-light-blue2 {
  background-color: #5ECBF7;
}

.color-blue {
  color: #2178C3;
}

.back-blue {
  background-color: #2178C3;
}

.back-black {
  background-color: #000;
}

.color-green {
  color: #32B681;
}

.back-green {
  background-color: #32B681;
}

.back-pink {
  background-color: #6953AC;
}

.color-calendar {
  color: #98D45F;
}

.back-calendar {
  background-color: #98D45F;
}

.color-white {
  color: #fff;
}

.back-library {
  background-color: #EF6325;
}

.back-contact {
  background-color: #F6AA31;
}

.color-contact {
  color: #F6AA31;
}

.color-library {
  color: #EF6325;
}

.back-manage {
  background-color: #C0C3C4;
}

.back-light-yellow {
  background-color: rgba(246, 170, 49, .25);
}

.back-yellow {
  background-color: #FDF13C;
}

.back-white {
  background-color: #fff;
}