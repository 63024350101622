$directions: t, b, r, l, x, y;
$marginSizes: 0, 4, 6, 8, 10, 12, 16, 20, 24, 28, 30, 32, 36, 40, 144, 50, 60, 76, 80, 100, 120, 140, 194, 430, 172, 280;

@each $size in $marginSizes {
  $newSize: calc(1px * $size); // calc(1rem * $size / 16);

  @each $direction in $directions {
    @if $direction == r {
      .m#{$direction}-#{$size} {
        margin-right: $newSize !important;
      }
      .p#{$direction}-#{$size} {
        padding-right: $newSize !important;
      }
    }
    @if $direction == l {
      .m#{$direction}-#{$size} {
        margin-left: $newSize !important;
      }
      .p#{$direction}-#{$size} {
        padding-left: $newSize !important;
      }
    }
    @if $direction == t {
      .m#{$direction}-#{$size} {
        margin-top: $newSize !important;
      }
      .p#{$direction}-#{$size} {
        padding-top: $newSize !important;
      }
    }
    @if $direction == b {
      .m#{$direction}-#{$size} {
        margin-bottom: $newSize !important;
      }
      .p#{$direction}-#{$size} {
        padding-bottom: $newSize !important;
      }
    }
    @if $direction == y {
      .m#{$direction}-#{$size} {
        margin-bottom: $newSize !important;
        margin-top: $newSize !important;
      }
      .p#{$direction}-#{$size} {
        padding-bottom: $newSize !important;
        padding-top: $newSize !important;
      }
    }
    @if $direction == x {
      .m#{$direction}-#{$size} {
        margin-left: $newSize !important;
        margin-right: $newSize !important;
      }
      .p#{$direction}-#{$size} {
        padding-left: $newSize !important;
        padding-right: $newSize !important;
      }
    }
  }
}

.m-auto {
  margin: auto;
}
