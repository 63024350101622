.bannerTopPage {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  overflow: hidden;
}

.bannerImage {
  width: 100%;
  position: relative;
  padding-top: calc(100vh - 56px);
  overflow: hidden;

  @media (max-width: 768px) {
    padding-top: calc(100vh - 44px);
  }

  img {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    object-fit: contain;
  }
}